/********************************************************************

	DATAUSA MAIN STYLES
	-----------------------------
	Company: VanTassellProjects .com
	Authors: Mike Van Tassell - mike@vantassellprojects.com
	Date: January 20, 2011 

*********************************************************************/
/********************************************************************

	DATAUSA - RESET
	-----------------------------
	Company: VanTassellProjects .com
	Authors: Mike Van Tassell - mike@vantassellprojects.com
	Date: January 20, 2011

*********************************************************************/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

fieldset, img {
  border: 0; }

address, caption, cite, code, dfn, em, strong, th, var {
  font-style: normal;
  font-weight: normal; }

ol, ul {
  list-style: none; }

caption, th {
  text-align: left; }

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal; }

q:before, q:after {
  content: ''; }

abbr, acronym {
  border: 0; }

/***FLOAT CLEARING CLASS*******************************************************/
.clearfix:after {
  content: ".";
  clear: both;
  display: block;
  height: 0;
  visibility: hidden; }

.clearfix {
  display: inline-block; }

html[xmlns] .clearfix {
  display: block; }

* html .clearfix {
  height: 1%; }

body {
  background: #e8e8e8 url(../images/bg_noise.jpg);
  font-family: Arial, Helvetica, sans-serif; }

a {
  color: #124676;
  text-decoration: none; }

a:hover {
  color: #000; }

.float_wrapper {
  overflow: hidden;
  width: 100%; }

.main {
  margin: auto; }

.resize {
  width: 960px;
  margin: auto; }

dl dt {
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #50606c;
  text-shadow: 0 1px 0 white; }

dl dd {
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 18px;
  color: #50606c;
  text-shadow: 0 1px 0 white; }
  dl dd p {
    font-size: 12px !important; }

strong {
  font-weight: bold;
  color: #50606c; }

/********** header **********/
.header_resize {
  background: #112d42 url(../images/bg_header.jpg) no-repeat top center; }

.header {
  width: 90%;
  max-width: 980px;
  padding: 0;
  margin: 0 auto;
  border-bottom: 1px solid #091924;
  position: relative; }

.header_blog {
  background: #112d42 url(../images/bg_slider.jpg) no-repeat top center;
  border-bottom: 1px #141e27 solid; }

#about .header_blog {
  background: #112d42 url(../images/bg_header_clock.jpg) no-repeat top center; }

#contact .header_blog {
  background: #112d42 url(../images/bg_header_city.jpg) no-repeat top center; }

#services .header_blog {
  background: #112d42 url(../images/bg_board_room.jpg) no-repeat top center; }

#analysis .header_blog {
  background: #112d42 url(../images/bg_header_analysis.jpg) no-repeat top center; }

#careers .header_blog {
  background: #112d42 url(../images/bg_lp_recruiting.jpg) no-repeat 100px -30px; }

.header_blog_resize {
  width: 980px;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box; }
  @media (max-width: 980px) {
    .header_blog_resize {
      width: 100%;
      padding: 0 20px; } }

.header_blog_resize h2 {
  font: bold 36px Arial, Helvetica, sans-serif;
  color: #fff;
  padding: 40px 0;
  border-top: 1px #344c5e solid; }

.header_blog_resize img {
  float: right;
  padding: 45px 0 0 0; }

/* 2 */
.header_blog2 {
  background: url(../images/header_blog_bg.gif) top center repeat-x;
  padding: 20px 0 0 0;
  height: 95px; }

.header_blog2 h2 {
  font: bold 36px Arial, Helvetica, sans-serif;
  color: #fff;
  padding: 10px 0 0 0; }

/* logo */
.logo {
  padding: 0 0 5px 0;
  margin: 38px 0 22px 0;
  float: left; }
  .logo img {
    width: 100%; }

.slogan {
  color: #7ba6cc;
  font-family: Verdana, Geneva, sans-serif;
  text-transform: uppercase;
  float: left;
  font-size: 10px;
  margin: 48px 0 0 0;
  text-shadow: 0 -1px 0 black; }

.social {
  color: #7ba6cc;
  font-family: Verdana, Geneva, sans-serif;
  float: left;
  font-size: 10px;
  margin: 48px 0 0 30px;
  text-shadow: 0 -1px 0 black; }

/*menu*/
.menu {
  padding: 48px 0 0 0;
  margin: 0;
  margin-left: 40px;
  float: right; }

.menu ul {
  text-align: right;
  list-style: none;
  float: right; }

.menu ul li {
  float: left;
  padding: 0 10px;
  border: 0; }

.menu ul li a {
  float: left;
  padding: 8px 0;
  color: #8f999e;
  font: normal 12px Arial, Helvetica, sans-serif;
  text-shadow: 0 -1px 0 black; }

.menu ul li a:hover {
  color: #fff;
  border-bottom: 3px solid 7aa5cc; }

.menu ul li a.active {
  color: #fff;
  border-bottom: 3px solid #7aa5cc; }

/*Phone Number*/
.phone_number {
  margin: 0;
  width: 220px;
  position: absolute;
  top: 10px;
  right: 0; }
  @media (max-width: 960px) {
    .phone_number {
      position: static;
      float: left;
      padding-top: 48px;
      margin-left: 40px; } }
  @media (max-width: 615px) {
    .phone_number {
      display: none; } }

.phone_number h3 {
  font-size: 28px;
  text-align: right;
  color: #fff;
  text-shadow: 0 -1px 1px black;
  line-height: 26px;
  font-weight: bold; }

/* search_bg */
.search_bg {
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.25);
  margin: 15px auto 10px auto;
  width: 950px;
  display: block;
  background: #ccc url(../images/bg_title_bar.gif);
  border-top: 1px #a7a7a7 solid;
  border-bottom: 1px #f9f9f9 solid;
  color: #50606c;
  font-size: 28px;
  font-weight: bold;
  padding: 10px 0 15px 30px;
  text-shadow: 0 1px 0 white; }

.search_bg:hover {
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  background: #ccc url(../images/bg_title_bar_hover.gif);
  color: #50606c; }

.specialist {
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.25);
  width: 609px;
  float: left;
  background: #ccc url(../images/bg_title_bar.gif);
  border-top: 1px #a7a7a7 solid;
  border-bottom: 1px #f9f9f9 solid;
  color: #50606c;
  display: block;
  font-size: 28px;
  font-weight: bold;
  padding: 15px 15px 15px 30px;
  text-shadow: 0 1px 0 white; }
  @media (max-width: 960px) {
    .specialist {
      width: 90%; } }

.specialist:hover {
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  background: #ccc url(../images/bg_title_bar_hover.gif);
  color: #50606c; }

/* */
.body_resize_top_resize {
  margin: 10px auto;
  padding: 0 10px; }

.body_resize {
  max-width: 980px;
  width: 100%;
  margin: auto;
  border-bottom: 1px #b5b5b5 solid;
  padding-bottom: 20px; }

.body_resize_top {
  background: url(../images/body_top_bg.gif) top no-repeat;
  margin: 0;
  padding: 0; }

.body_resize_bottom {
  margin: 0;
  padding: 10px;
  width: 960px;
  background: url(../images/body_bottom_bg.gif) bottom no-repeat; }

.body {
  padding: 20px 0 0 0;
  margin: 0 auto;
  border-top: 1px #fff solid; }

.body h1 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #112d42;
  text-shadow: 0 1px 0 white; }

.body h2 {
  font: bold 18px Arial, Helvetica, sans-serif;
  color: #112d42;
  border-bottom: 1px  solid #a3a6a9;
  padding: 10px 0;
  margin: 0 0 10px 0;
  text-shadow: 0 1px 0 white; }

.body h4 {
  font-size: 18px;
  font-weight: bold;
  color: #112d42;
  padding: 10px 0;
  margin: 0 0 10px 0;
  text-shadow: 0 1px 0 white; }

.body h5 {
  font-size: 16px;
  color: #50606c;
  padding: 10px 0;
  margin: 0 0 10px 0;
  font-weight: bold;
  text-shadow: 0 1px 0 white; }

.body h3 {
  font-size: 18px;
  font-weight: bold;
  color: #575757;
  padding: 10px 0;
  margin: 0 0 10px 0; }

.body p {
  font-size: 12px;
  color: #50606c;
  line-height: 18px;
  padding: 3px 0;
  margin: 0;
  margin-bottom: 15px;
  text-shadow: 0 1px 0 white; }

.body .left p {
  font-size: 14px; }

.body ul {
  padding: 5px 0;
  margin: 0 0 15px 20px; }

.body ul li {
  padding: 2px 0;
  font-size: 12px;
  color: #3e4d59;
  line-height: 18px;
  list-style-type: disc;
  text-shadow: 0 1px 0 white; }

.body p span {
  color: #5c302f;
  font-weight: bold; }

.body img {
  margin: 10px auto;
  padding: 0; }

.body img.floated {
  float: right;
  margin: 5px 20px;
  padding: 0; }

.body img.floated2 {
  float: left;
  margin: 5px 20px 5px 0;
  padding: 0; }

.right {
  width: 290px;
  margin: 0;
  padding: 10px 15px;
  float: right; }

.right ul {
  list-style: none; }

.right li {
  padding: 5px;
  margin: 0;
  border-bottom: 1px solid #dbdbdb; }

.right li a {
  font: normal 11px Arial, Helvetica, sans-serif;
  color: #7d7d7d;
  text-decoration: none; }

.right li a:hover {
  font: bold 11px Arial, Helvetica, sans-serif;
  color: #343434; }

.left {
  width: 654px;
  margin: 0;
  padding: 10px 0 10px 0;
  float: left;
  box-sizing: border-box; }
  @media (max-width: 960px) {
    .left {
      width: 100%;
      float: none;
      padding: 20px; } }

.blog {
  width: 100%;
  margin: 0;
  padding: 20px;
  clear: both;
  box-sizing: border-box; }
  @media (min-width: 768px) {
    .blog {
      width: 100%;
      margin: 0;
      padding: 20px;
      clear: both; } }
  @media (min-width: 960px) {
    .blog {
      width: 30%;
      margin: 0;
      padding: 10px 0 10px 0;
      float: right;
      clear: none; } }

.two_column {
  width: 100%;
  padding: 20px;
  box-sizing: border-box; }
  @media (min-width: 768px) {
    .two_column {
      width: 43%;
      float: left;
      padding: 0 10px;
      margin-right: 0; }
      .two_column:first-of-type {
        margin-right: 40px; } }
  @media (min-width: 960px) {
    .two_column {
      width: 30%;
      margin-right: 40px;
      padding: 10px 0 10px 0;
      float: left; } }

.port {
  width: 270px;
  float: left;
  margin: 10px 10px 10px 5px;
  padding: 5px;
  background: #fff;
  border: 1px solid #ddd; }

.done, .error {
  display: none; }

.dataform form fieldset p .hightlight {
  background: url(../images/bg_form_highlight.gif) no-repeat 0 0; }

.dataform form p {
  color: #5b6b77;
  font-weight: bold;
  margin-bottom: 0; }

.dataform form fieldset p input {
  display: block;
  padding: 7px 4px 9px 6px;
  width: 274px;
  margin: 0;
  border: none;
  color: #5b6b77;
  background: url(../images/bg_form_text.gif) no-repeat 0 0;
  margin-top: 3px;
  outline: none; }

.dataform form fieldset .select {
  display: block;
  padding: 7px 4px 9px 6px;
  width: 274px;
  line-height: 29px;
  margin: 0;
  border: none;
  color: #5b6b77;
  background: url(../images/bg_form_text.gif) no-repeat 0 0;
  margin-top: 3px;
  outline: none; }

.dataform form fieldset p input.radio {
  background: none;
  display: inline-block;
  margin: 0 10px 0 3px;
  margin-top: 3px;
  width: 20px; }

form fieldset p textarea {
  display: block;
  padding: 7px 4px 6px 6px;
  width: 274px;
  height: 103px;
  border: none;
  background: url(../images/bg_form_notes.gif) no-repeat 0 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #5b6b77;
  overflow: auto;
  resize: none;
  margin-top: 3px;
  outline: none; }

form .hightlight {
  background: url(../images/bg_form_highlight.gif) no-repeat 0 0; }

form .submit {
  border: none;
  width: 91px;
  height: 37px;
  font-size: 0px;
  cursor: pointer;
  margin: 0;
  background: url(../images/btn_submit.gif) no-repeat 0 0; }

form .clearform {
  border: none;
  width: 92px;
  height: 37px;
  font-size: 0px;
  cursor: pointer;
  margin: 0;
  background: url(../images/btn_clear.gif) no-repeat 0 0; }

/*************footer**********/
.footer_resize {
  max-width: 980px;
  width: 100%;
  margin: 0 auto;
  border-top: 1px #fcfcfc solid;
  padding-top: 20px; }
  @media (min-width: 600px) {
    .footer_resize {
      padding: 10px; } }

.footer {
  padding-bottom: 30px; }

.footer p {
  font: normal 11px  Arial, Helvetica, sans-serif;
  color: #737373;
  text-shadow: 0 1px 0 white; }

.footer a {
  font-size: 11px;
  color: #9a9a9a;
  padding: 5px; }

.footer a:hover {
  color: #737373; }

.footer p.right {
  text-align: right;
  display: block;
  max-width: 400px;
  width: 100%;
  margin: 0;
  padding: 10px 0 0 0;
  float: right; }
  @media (max-width: 960px) {
    .footer p.right {
      float: none;
      text-align: center;
      margin-top: 20px;
      clear: both; } }

.footer .footer_left {
  display: block;
  max-width: 500px;
  width: 50%;
  float: left; }
  @media (max-width: 960px) {
    .footer .footer_left {
      float: none;
      width: 100%;
      text-align: center; } }

.footer img.loggo {
  float: left;
  display: block; }

.footer .footer_links {
  float: left;
  display: block;
  max-width: 500px;
  padding: 20px 0 0 0; }

.footer img.rss {
  float: left;
  margin: 5px; }

.footer_social {
  float: left;
  margin-left: 30px;
  margin-top: 5px; }

.footer_social .facebook {
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.25);
  float: left;
  width: 16px;
  height: 32px;
  background: #ccc url(../images/bg_title_bar.gif);
  border-top: 1px #a7a7a7 solid;
  border-bottom: 1px #f9f9f9 solid;
  color: #50606c;
  display: block;
  font-weight: bold;
  padding: 1px 3px 0 16px;
  text-shadow: 0 1px 0 white; }

.footer_social .facebook:hover {
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
  background: #ccc url(../images/bg_title_bar_hover.gif);
  color: #50606c; }

.footer_social .facebook_text {
  display: block;
  float: left;
  width: 100px;
  padding-top: 10px; }

p.clr, .clr {
  clear: both;
  background: none; }

.bg {
  border-bottom: 1px solid #dbdbdb;
  clear: both;
  margin: 10px 0;
  background: none; }

/* Slider */
#slider {
  max-width: 980px;
  width: 100%;
  margin: 0 auto;
  border-top: 1px #344c5e solid;
  position: relative; }

div#slideshow {
  float: left;
  width: 100%; }
  div#slideshow div {
    box-sizing: border-box; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-slide {
  height: 250px;
  display: block;
  float: left;
  background: #112d42 url(../images/bg_slider.jpg) no-repeat top center; }

.slick-dotted.slick-slider {
  margin-bottom: 0 !important; }

.slick-slide#processing {
  background: #112d42 url(../images/bg_slider_card_processing.jpg) no-repeat top center; }

.slick-slide#emv {
  background: #112d42 url(../images/bg_slider_EMV.jpg) no-repeat top center; }

.slick-slide#support {
  background: #112d42 url(../images/bg_slider_support.jpg) no-repeat top center; }

.slick-slide#accepting {
  background: #112d42 url(../images/bg_slider_accepting.jpg) no-repeat top center; }

.slick-slide#low_rates {
  background: #112d42 url(../images/bg_slider_low_rates.jpg) no-repeat top center; }

.slick-slide img {
  float: right; }

.slick-slide .text_item {
  float: left;
  padding: 40px 0 0 24px; }

.slick-slide .text_item h2 {
  font-size: 38px;
  font-weight: bold;
  color: #fff;
  padding: 0 0 15px 0;
  margin: 0;
  line-height: 1.2em;
  text-shadow: 0 -1px 2px black; }
  @media (max-width: 500px) {
    .slick-slide .text_item h2 {
      font-size: 28px; } }

.slick-slide .text_item h3 {
  font-size: 24px;
  color: #99b5cf;
  padding: 0 0 20px 0;
  text-shadow: 0 -1px 1px black;
  line-height: 26px; }
  @media (max-width: 500px) {
    .slick-slide .text_item h3 {
      font-size: 18px; } }

.slick-slide .text_item p {
  font: normal 12px Arial, Helvetica, sans-serif;
  color: #8c9da1;
  padding: 0 0 20px 0;
  margin: 0;
  line-height: 1.8em; }

.slick-slide .text_item img {
  margin: 0 auto;
  padding: 0;
  float: left; }

#slider_controls {
  margin: 0 auto;
  z-index: 1000;
  width: 254px;
  position: absolute;
  bottom: -30px;
  right: 0; }

#slider_controls h2 {
  width: 600px;
  float: left;
  font: normal 11px Arial, Helvetica, sans-serif;
  color: #676767;
  padding: 30px 0 0 0;
  margin: 0; }

#slider_controls ul {
  width: 100px;
  float: right; }

#slider_controls ul li {
  margin: 0;
  padding: 0;
  list-style: none; }

#slider_controls ul li {
  float: left;
  display: block; }

#slider_controls ul li a {
  width: 10px;
  height: 10px;
  background: url("../images/tabs_2.gif") no-repeat center center;
  display: block;
  float: left;
  padding: 10px 2px 0 2px;
  margin: 2px !important;
  margin: 1px 1px;
  outline: none; }

#slider_controls ul li a:focus {
  outline: none; }

#slider_controls ul li a:hover,
#slider_controls ul li a.activeSlide {
  color: #979797;
  background: url("../images/tabs_1.gif") no-repeat center center; }

#map_canvas {
  height: 400px;
  width: 100%; }

#lp_hero {
  width: 980px;
  margin: 0 auto;
  border-top: 1px #344c5e solid;
  position: relative; }

.hero_content {
  width: 980px;
  height: 250px;
  float: left;
  display: block;
  clear: both; }

.lp_target_client {
  background: #112d42 url(../images/bg_lp_placeholder.jpg) no-repeat 0 -50px; }

.lp_recruiting {
  background: #112d42 url(../images/bg_lp_recruiting.jpg) no-repeat 0 0px; }

.hero_content img {
  float: right; }

.hero_content .text_item {
  float: left;
  padding: 40px 0 0 24px; }

.hero_content .text_item h2 {
  font-size: 34px;
  font-weight: bold;
  color: #fff;
  padding: 0 0 15px 0;
  margin: 0;
  line-height: 1.2em;
  text-shadow: 0 -1px 2px black; }

.hero_content .text_item h3 {
  font-size: 24px;
  color: #99b5cf;
  padding: 0 0 20px 0;
  text-shadow: 0 -1px 1px black;
  line-height: 26px; }

.hero_content .text_item p {
  font: normal 12px Arial, Helvetica, sans-serif;
  color: #8c9da1;
  padding: 0 0 20px 0;
  margin: 0;
  line-height: 1.8em; }

.hero_content .text_item img {
  margin: 0 auto;
  padding: 0;
  float: left; }

/*************footer**********/
.info_left, .info_right {
  float: left;
  width: 300px;
  display: block; }

@media (max-width: 960px) {
  .menu {
    display: none; } }

.menu ul {
  position: relative; }
  .menu ul li {
    position: relative; }

.menu .has-nav {
  margin-right: 8px; }
  .menu .has-nav:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    right: -2px;
    top: 13px;
    border-width: 5px 4px 0 4px !important;
    border-color: #909090 transparent;
    border-style: solid; }

.has-nav:hover:after {
  border-width: 5px 4px 0 4px !important;
  border-color: #fff transparent;
  border-style: solid; }

.has-nav:hover > a {
  color: #fff; }

.menu ul ul {
  display: none;
  position: absolute;
  top: 100%;
  left: 10px;
  background: #e8e8e8;
  padding: 0;
  z-index: 10;
  transition: 0.5s; }
  .menu ul ul li {
    display: block;
    width: 125px;
    border-bottom: 1px solid #ccc;
    text-align: left; }
    .menu ul ul li:hover {
      background: #ccc; }
    .menu ul ul li a {
      display: block;
      width: 100%;
      color: #000;
      text-shadow: none; }
      .menu ul ul li a:hover {
        color: #000; }

.menu ul li:hover > ul {
  display: block; }

.mobile-menu {
  display: none; }
  @media (max-width: 960px) {
    .mobile-menu {
      display: block;
      position: absolute;
      right: 0px;
      top: 45px; } }
  .mobile-menu .burger {
    background: transparent url("/resources/images/icon-burger.png") no-repeat;
    width: 50px;
    height: 30px;
    background-size: contain; }
    .mobile-menu .burger:hover {
      cursor: pointer; }

.mobile-nav {
  display: none;
  padding-top: 50px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #efefef;
  z-index: 9999; }
  .mobile-nav li {
    text-align: center;
    padding: 15px;
    font-size: 22px;
    border-bottom: 1px solid #ccc; }
  .mobile-nav .close {
    display: block;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 10px;
    right: 10px;
    text-indent: -9999em;
    background: transparent url("/resources/images/icon-close.png") no-repeat;
    background-size: cover; }
  .mobile-nav .mobile-number {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 24px; }
    .mobile-nav .mobile-number a {
      color: #000;
      font-weight: bold;
      display: block;
      padding: 20px;
      background-color: #333;
      color: #fff; }

#services-pci .contact {
  font-size: 12px;
  line-height: 18px; }

.service {
  box-sizing: border-box;
  padding: 20px;
  width: 48%;
  float: left;
  height: 250px;
  border-bottom: 1px solid #ccc; }
  .service:after {
    content: "";
    display: table;
    clear: both; }
  .service:nth-of-type(2n) {
    margin-right: 2%; }
  @media (max-width: 700px) {
    .service {
      width: 100%;
      float: none;
      height: auto;
      clear: both; } }
  .service img {
    width: 90px;
    height: auto;
    float: left;
    margin-right: 10px; }
  .service .image {
    width: 100%;
    height: 70px;
    background-color: #ccc; }
    .service .image.image-emv {
      background: transparent url("/resources/images/bg_emv.jpg") no-repeat;
      background-size: cover; }
    .service .image.image-pci {
      background: transparent url("/resources/images/bg_pci.jpg") no-repeat;
      background-size: cover; }

.service-bottom {
  height: auto;
  border-bottom: none; }
  .service-bottom a.learn-more {
    display: inline-block;
    background-color: #124676;
    padding: 10px;
    border-radius: 3px;
    color: #fff;
    font-size: 12px; }
    .service-bottom a.learn-more:hover {
      background-color: #7aa5cc; }
